<template>
<div class="top-wrp">
    <router-link to="" class="top-set-icon"></router-link>
    <router-link to="/signIn" class="top-sign-icon"> <span class="stumb-icon"></span>签到领积分</router-link>
    <div class="top-head">
        <div class="avatar-wrap">
            <img :src="member.Member_Image?member.Member_Image:stImg" alt="avatar">
        </div>
        <div class="user-info">
            <router-link to="" class="user-discount-icon"> <span class="star-icon"></span>优惠券</router-link>
            <p class="user-name">{{member.Member_Name||'佚名'}}</p>
            <p>积分: {{member.fen||0}}</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            stImg: require("../assets/standImg.jpg"),
            member: {
                Member_Image: "",
                Member_Name: ""
            }
        }
    },
    created() {
        this.getInfo()
        // this.member = JSON.parse(localStorage.getItem("member"))
    },
    methods: {
        getInfo() {
            this.wpost({
                url: '/Ajax/Insurance/getinsurancelist',
                data: {
                    status: 0,
                    pagesize: 1,
                    pageindex: 1
                },
                succ: (data) => {
                    console.log("result:", data)
                    this.member = data.member

                }
            })
        }
    }
}
</script>

<style scoped>
.top-wrp {
    color: #fff;
    position: relative;
    padding: 5px;
    padding-top: 20px;
    height: 23vw;
    background: url("../assets/user_top_bg.png") no-repeat center top / 100% auto;

}

.top-sign-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    padding: 0 12px;
    position: absolute;
    right: 0;
    top: 21vw;
    border-radius: 10px 0 0 10px;
    background-image: linear-gradient(to right, #ffa94c, #ffd4a5)
}

.top-sign-icon::after,
.user-discount-icon::after {
    content: "";
    height: 5px;
    width: 5px;
    position: absolute;
    top: 7px;
    right: 5px;
    border: 1px solid transparent;
    border-right-color: #fff;
    border-bottom-color: #fff;
    transform: rotateZ(-45deg);
}

.stumb-icon {
    display: inline-block;
    height: 22px;
    width: 10px;
    margin-right: 3px;
    background: url("../assets/thumb.png") no-repeat center center / 100% auto;

}

.star-icon {
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-right: 3px;
    background: url("../assets/star.png") no-repeat center center / 100% auto;
}

.user-discount-icon::after {
    top: 4px;
}

.user-discount-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    padding-right: 12px;
    position: absolute;
    right: 0;
    top: 4px;
    background-image: linear-gradient(to right, #57575c, #363b3f);
    border-radius: 10px;

}

.top-set-icon {
    display: block;
    width: 26px;
    height: 26px;
    background: url("../assets/setIcon.png") no-repeat center center / 100% auto;
    position: absolute;
    top: 6px;
    right: 6px;
}

.top-head {
    margin-left: 10px;
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}

.user-info {
    width: calc(100% - 16vw - 6px);
    position: relative;
}

.user-info p {
    line-height: 1.6
}

.user-name {
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 70px);
}

.user-info p:last-child {
    font-size: 13px;
}

.avatar-wrap {
    margin-right: 6px;
    position: relative;
    width: 16vw;
    height: 16vw;
    border-radius: 50%;
    overflow: hidden;
}

.avatar-wrap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
</style>
