import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import qs from 'qs'
import NavBottom from "@/components/NavBottom.vue";
import SearchTop from "@/components/SearchTop.vue";
import Swiper from "@/components/Swiper.vue";
import NoticeLine from "@/components/NoticeLine.vue";
import ListItem from "@/components/ListItem.vue";
import ImgSwitch from "@/components/ImgSwitch.vue";
import UserPad from "@/components/UserPad.vue";

let app = createApp(App)


function isNil(val) {
    if (String(val) === 'null' || String(val) === 'undefined' || String(val) === '') {
        return true
    }
    return false
}
app.config.globalProperties.isNil= isNil
app.config.globalProperties.imgActiveAd = require('@/assets/bannerAdr.jpg')

app.config.compilerOptions.isCustomElement = tag => tag.startsWith('wx-')
app.config.globalProperties.axios = axios
app.config.globalProperties.ajxImg = function ajxImg(url, aobj, callback, method) {

    var xhr = new XMLHttpRequest();
    if (isNil(method)) {
        method = "POST";
    }
    xhr.open(method, url, true);
    xhr.addEventListener("readystatechange", function () {
        try {
            if (xhr.readyState == 4 && xhr.status == 200) {
                callback(JSON.parse(xhr.responseText));
            }
        } catch (error) {
            return new Error(error);
        }
    });


    xhr.send(aobj);

}
app.config.globalProperties.domin = "https://www.visasiv.cn"




let msgSwitch = res => {
	console.log("res", res.data.message)
	let tipMsg = ''
	switch (res.data.result) {
		case 'nologin':
			tipMsg = '未登录';			
			let fpath = (localStorage.getItem("gUrl") || "/")
			console.log("fpath:",fpath)
			// console.log("domin:",app.domin)
			location.href = "/gologin.aspx?RedirectUrl=www.visasiv.cn/weilife/$"+fpath;
			break;
		case 'errorcertno':
			tipMsg = '身份证有误';
			break;
		case 'merchantblacklist':
			tipMsg = '活动报名处罚中';
			break;
		case 'errormessage':
			tipMsg = String(res.data.message);
			break;
		case 'birthdaylimit':
			if(!isNil(res.data.errorproduct)){
				tipMsg ='购物车中,《'+ res.data.errorproduct.Product_Name +'》的活动产品，有以下限制：'+ res.data.message
			}
			else{
				tipMsg = '年龄限制';
			}
			break;
		case 'stockless':
			if(!isNil(res.data.errorproduct)){
				tipMsg ='购物车中,《'+ res.data.errorproduct.Product_Name +'》 的活动产品，有以下限制：'+'库存不足'
			}
			else{
				tipMsg = '库存不足';
			}
			break;
		case 'cartlimit':
			tipMsg = '购物车数量限制';
			break;
		case 'errorcount':
			tipMsg = '数量有误';
			break;
		case 'nomerchant':
			tipMsg = '未选择商家';
			break;
		case 'notenough':
			tipMsg = '不足起送费用';
			break;
		case 'notyours':
			tipMsg = '不是你的记录';
			break;
		case 'errorstatus':
			tipMsg = '状态有误';
			break;
		case 'errorinfo':
			tipMsg = '无查询结果';
			break;
		case 'invoicereceiveapply':
			tipMsg = '已经请求申领';
			break;
		case 'nosocialinsurance':
			tipMsg = '抱歉。未查询到你在本平台有“社保”缴费记录。如有疑问请添加下方平台客服微信咨询。';
			break;
		case 'timeover':
			tipMsg = '提交超时';
			break;
		case 'noticecourier':
			tipMsg = '订单异常，请联系你的保险专员';
			break;
		case 'countlimit':
			tipMsg = '购买数量限制';
			break;
		case 'emptycart':
			tipMsg = '购物车为空';
			break;
		case 'overbuytime':
			if(!isNil(res.data.errorproduct)){
				tipMsg ='购物车中,《'+ res.data.errorproduct.Product_Name +'》的活动产品，有以下限制：'+'不在活动时间内'
			}else{
				tipMsg = '不在活动时间内';
			}
			break;			
	}
	console.log('tipMsg:',tipMsg)
	return tipMsg;
}
let msgSwitchSpecial = res => {
	console.log("res", res.data.message)
	let tipMsg = ''
	switch (res.data.result) {
		case 'nologin':
			tipMsg = '未登录';			
			let fpath = (localStorage.getItem("gUrl") || "/")
			console.log("fpath:",fpath)
			// console.log("domin:",app.domin)
			location.href = "/gologin4.aspx?RedirectUrl=www.visasiv.cn/weilife/$"+fpath;
			break;
		case 'errorcertno':
			tipMsg = '身份证有误';
			break;
		case 'merchantblacklist':
			tipMsg = '活动报名处罚中';
			break;
		case 'errormessage':
			tipMsg = String(res.data.message);
			break;
		case 'birthdaylimit':
			if(!isNil(res.data.errorproduct)){
				tipMsg ='购物车中,《'+ res.data.errorproduct.Product_Name +'》的活动产品，有以下限制：'+ res.data.message
			}
			else{
				tipMsg = '年龄限制';
			}
			break;
		case 'stockless':
			if(!isNil(res.data.errorproduct)){
				tipMsg ='购物车中,《'+ res.data.errorproduct.Product_Name +'》 的活动产品，有以下限制：'+'库存不足'
			}
			else{
				tipMsg = '库存不足';
			}
			break;
		case 'cartlimit':
			tipMsg = '购物车数量限制';
			break;
		case 'errorcount':
			tipMsg = '数量有误';
			break;
		case 'nomerchant':
			tipMsg = '未选择商家';
			break;
		case 'notenough':
			tipMsg = '不足起送费用';
			break;
		case 'notyours':
			tipMsg = '不是你的记录';
			break;
		case 'errorstatus':
			tipMsg = '状态有误';
			break;
		case 'errorinfo':
			tipMsg = '无查询结果';
			break;
		case 'invoicereceiveapply':
			tipMsg = '已经请求申领';
			break;
		case 'nosocialinsurance':
			tipMsg = '抱歉。未查询到你在本平台有“社保”缴费记录。如有疑问请添加下方平台客服微信咨询。';
			break;
		case 'timeover':
			tipMsg = '提交超时';
			break;
		case 'noticecourier':
			tipMsg = '订单异常，请联系你的保险专员';
			break;
		case 'countlimit':
			tipMsg = '购买数量限制';
			break;
		case 'emptycart':
			tipMsg = '购物车为空';
			break;
		case 'overbuytime':
			if(!isNil(res.data.errorproduct)){
				tipMsg ='购物车中,《'+ res.data.errorproduct.Product_Name +'》的活动产品，有以下限制：'+'不在活动时间内'
			}else{
				tipMsg = '不在活动时间内';
			}
			break;
		default:		
			tipMsg = '未知错误';	
	}
	console.log('tipMsg:',tipMsg)
	return tipMsg;
}
window.ajxImg = (url, aobj, callback, method) => {

    var xhr = new XMLHttpRequest();
    
    if (isNil(method)) {
        method = "POST";
    }

    var fd = new FormData();
    fd.append('imgFile', aobj);

    console.log(fd);

    xhr.open(method, url, true);
    xhr.addEventListener("readystatechange", function () {
        try {
            if (xhr.readyState == 4 && xhr.status == 200) {
                callback(JSON.parse(xhr.responseText));
            }
        } catch (error) {
            return new Error(error);
        }
    });



    xhr.send(fd);

}

app.config.globalProperties.wget = wobj => {
	axios({
		url: wobj.url,
		params: wobj.data || {},
		method: 'GET',
		headers: {
			'Content-Type': "application/x-www-form-urlencoded; charset=utf-8"
		},}).then(res => {
			if (res.data.result !== 'success') {
				if (res.data.errMsg) {					
						
						alert("请求出错：" + JSON.stringify(res.data.errMsg)) 
					
				} else {
					var tipMsg = msgSwitch(res)
						
                    alert("—— " + tipMsg + " ——") 	
					
					if(!!wobj.fail){
						wobj.fail(res)
					}
				}
				return
			}
			
			wobj.succ(res.data)
		})
	
}
app.config.globalProperties.wpost = wobj => {

	axios({
		url: wobj.url,
		data: qs.stringify(wobj.data||{}),
		method: 'POST',
		headers: {
			'Content-Type': "application/x-www-form-urlencoded; charset=utf-8"
		},}).then( res => {
			if (res.data.result !== 'success') {
				if (res.data.errMsg) {
					
                    alert("请求出错：" + JSON.stringify(res.data.errMsg))
				} else {
					var tipMsg = msgSwitch(res)
					
					alert("—— " + tipMsg + " ——")

					if(!!wobj.fail){
						wobj.fail(res)
					}
				}
				return
			}
			wobj.succ(res.data)
		})
	
}

app.config.globalProperties.spost = wobj => {

	axios({
		url: wobj.url,
		data: qs.stringify(wobj.data||{}),
		method: 'POST',
		headers: {
			'Content-Type': "application/x-www-form-urlencoded; charset=utf-8"
		},}).then( res => {
			if (res.data.result !== 'success') {
				if (res.data.errMsg) {
					
                    alert("请求出错：" + JSON.stringify(res.data.errMsg))
				} else {
					var tipMsg = msgSwitchSpecial(res)
					
					alert("—— " + tipMsg + " ——")

					if(!!wobj.fail){
						wobj.fail(res)
					}
				}
				return
			}
			wobj.succ(res.data)
		})
	
}



app.component("NavBottom",NavBottom)
app.component("SearchTop",SearchTop)
app.component("Swiper",Swiper)
app.component("NoticeLine",NoticeLine)
app.component("ListItem",ListItem)
app.component("ImgSwitch",ImgSwitch)
app.component("UserPad",UserPad)






app.use(store).use(router).mount('#app')
