<template>
    <div class="notice-wrap">
        <img class="notice-logo" src="../assets/headHot.png" alt="">
        <div class="notice-list">
            <ul class="list" :style="'transform:translateY(-'+ indx*40 +'px)'">
                <li v-for="(item,index) in news" :key="index">
                    {{item.News_Title}}
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
export default {
  props: {
    news: {
      require:true,
      type: Array
    }
  },
  data() {
    return {
      indx: 0,
    };
  },
  created() {
    let st= "";
    if(this.news.length == 0){
      clearInterval(st)
    }
    st =  setInterval(() => {
      if (this.indx == this.news.length - 1) {
        this.indx = 0;
      } else {
        this.indx += 1;
      }
    }, 3000);
  }
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list {
  transition: all 300ms ease;
}
.notice-list {
  width: calc(100% - 80px);
  height: 40px;
  overflow: hidden;
}
li {
  line-height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 12px;
}
.notice-wrap {
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin: 10px 5px;
  background-image: linear-gradient(to right, #fff, #ecebeb);
}
.notice-logo {
  height: 16px;
}
</style>