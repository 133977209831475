<template>
    <div>
        <div class="search-top">
            <div class="local-wrap">
                <img :src="localIcon" alt="localIcon">
                <span class="local-name">温州市</span>
                <img :src="arrow" alt="arrow">
            </div>
            <div class="search-wrap">
                <input type="text" v-model="keyword" placeholder="请输入要搜索的关键字">
                <a href="javascript:void(0)" @click="jumpToSearch">
                    <img src="../assets/search.png" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                localIcon: require("../assets/localIcon.png"),
                arrow: require("../assets/arrow.png"),
                keyword:"",
            }
        },
        methods:{ 
            jumpToSearch(){
                if(this.keyword==""){
                    alert("请输入要搜索的关键字")
                    return false;

                }
                this.$router.push({
                    path:"/search/"+this.keyword
                })
            }
        }
    }
</script>

<style scoped>
    .search-top,
    .search-wrap,
    .local-wrap {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }
    .search-top{
        padding: 5px
    }
    .search-wrap input{
        background: none;
        border: none;
        outline: none;
        color: #fff;
        padding-left: 6px;
        box-sizing: border-box;
        width: calc(100% - 37px)
    }
    .search-wrap input::placeholder{
        color: rgba(255, 255, 255, 0.5)
    }
    .search-wrap img{
        width: 15px;
        vertical-align: middle;
        position: relative;
        top: -6px;
        left: -2px;
    }
    .search-wrap a{
        display: inline-block;
        width:37px;
        height: 20px;
        text-align: center;
        box-sizing: border-box;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
    .local-wrap{
        width:25%
    }
    .local-name {
        font-size: 12px;
        color: #fff;
        text-align: center;
        display: inline-block;
        width: calc(100% - 26px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .local-wrap img {
        width: 10px;
        margin:0 1.5px;
    }
    .search-wrap{
        height: 30px;
        line-height: 30px;
        margin: 5px 0;
        width:73%;
        box-sizing: border-box;
        border-radius:15px;
        background-color: rgba(255, 255, 255, 0.3);
    }
</style>