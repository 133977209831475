<template>
    <div class="nav-btm">
        <div class="nav-content">
            <!-- <div class="nav-left nav-item">
            <router-link to="/" :class="checkNum == 1?'on':''">
                <img class="nav-logo" :src="checkNum == 1?navIcon1_active:navIcon1" alt="logo">
                <span> 教育 </span>
            </router-link>
            <router-link to="/medicare" :class="checkNum == 2?'on':''">
                <img class="nav-logo" :src="checkNum == 2?navIcon2_active:navIcon2" alt="logo">
                <span> 医保</span>
            </router-link>
        </div>
        <div class="nav-center nav-item">
          <div class="nav-center-wrap">
            <router-link to="/active" :class="checkNum == 3?'on':''">
                <div class="nav-center-icon">
                    <img class="nav-logo" src="../assets/navIcon_00.png" alt="logo">
                </div>
                <span> 活动</span>
            </router-link>
          </div>
        </div>
        <div class="nav-right nav-item">
            <router-link to="/shop" :class="checkNum == 4?'on':''">
                <img class="nav-logo" :src="checkNum == 4?navIcon3_active:navIcon3" alt="logo">
                <span>商城</span>
            </router-link>
            <router-link to="/user" :class="checkNum == 5?'on':''">
                <img class="nav-logo" :src="checkNum == 5?navIcon4_active:navIcon4" alt="logo">
                <span>我的</span>
            </router-link>
            </div>-->
            <router-link to="/" :class="checkNum == 3?'on':''">
                <img class="nav-logo" :src="checkNum == 3?navIcon9_active:navIcon9" alt="logo" />
                <span>活动</span>
            </router-link>
            <router-link to="/shop" :class="checkNum == 4?'on':''">
                <img class="nav-logo" :src="checkNum == 4?navIcon3_active:navIcon3" alt="logo" />
                <span>商城</span>
            </router-link>
            <router-link to="/user" :class="checkNum == 5?'on':''">
                <img class="nav-logo" :src="checkNum == 5?navIcon4_active:navIcon4" alt="logo" />
                <span>我的</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavBottom",
    props: {
        checkNum: { type: Number, default: 0 },
    },
    data() {
        return {
            navIcon1: require("@/assets/navIcon_38.png"),
            navIcon1_active: require("@/assets/navIcon_38_active.png"),
            navIcon2: require("@/assets/navIcon_40.png"),
            navIcon2_active: require("@/assets/navIcon_40_active.png"),
            navIcon3: require("@/assets/navIcon_42.png"),
            navIcon3_active: require("@/assets/navIcon_42_active.png"),
            navIcon4: require("@/assets/navIcon_44.png"),
            navIcon4_active: require("@/assets/navIcon_44_active.png"),
            navIcon9: require("@/assets/navIcon_99.png"),
            navIcon9_active: require("@/assets/navIcon_99_active.png"),
        };
    },
};
</script>

<style scoped>
.nav-logo {
    display: block;
    width: 50px;
    margin: 0 auto;
}

.nav-center-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    width: 41px;
    border-radius: 50%;
    margin: 0 auto;
    background-image: linear-gradient(to bottom, #4990f2, #5e6ffb);
    box-shadow: 0 0 8px #999;
    overflow: hidden;
}

.nav-center-icon .nav-logo {
    width: 25px;
}

.nav-btm {
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    height: 70px;
    background-image: linear-gradient(
        to bottom,
        transparent,
        transparent 46px,
        #fff 46.1px,
        #fff
    );
    background-size: 500px auto;
    background-repeat: no-repeat;
    background-position: center top;
}
/* .nav-center-wrap{
  background-image: linear-gradient(to right,#fff 3%,transparent 3.1%, transparent 97%, #fff 97.1%, #fff);
} */
.nav-content {
    min-width: 320px;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 6px;
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: nowrap;
    width: 100%;
    background-color: #fff;
}
.nav-content a{
    text-align: center;
}
.nav-item {
    width: 50%;
}
.nav-item a {
    display: inline-block;
    text-decoration: none;
    color: #888;
    text-align: center;
}
.nav-content a {
    width: 32%;
    display: inline-block;
    text-decoration: none;
    color: #888;
}

.nav-content a.on {
    color: #4b7de4;
}
.nav-center-wrap {
    position: relative;
    background-image: url("../assets/navIconBg.png");
    background-repeat: no-repeat;
    background-size: 63px auto;
    background-position: center 10px;
}
.nav-center-wrap::after,
.nav-center-wrap::before {
    content: "";
    width: calc(50% - 30px);
    background-color: #fff;
    height: 52px;
    position: absolute;
    bottom: 0;
    display: block;
}

.nav-center-wrap::after {
    right: 0;
}
.nav-center-wrap::before {
    left: 0;
}

@media screen and (max-width: 375px) {
    .nav-center-wrap::after,
    .nav-center-wrap::before {
        display: none;
    }
}

.nav-center span {
    margin-top: 3px;
    background-color: transparent !important;
}
.nav-center.nav-item a {
    font-size: 0 !important;
    width: 100%;
}
.nav-center.nav-item {
    width: 20%;
}
.nav-left,
.nav-right {
    background-color: #fff;
    border-top: 1px solid #efefef;
}
.nav-left {
    border-radius: 0 2px 0 0;
    box-shadow: -1px -2px 3px #efefef;
}
.nav-right {
    border-radius: 2px 0 0 0;
    box-shadow: 1px -2px 3px #efefef;
}

.nav-item a.on {
    color: #4b7de4;
}
.nav-item span {
    display: block;
    padding-top: 3px;
    font-size: 12px;
    background-color: #fff;
}
</style>
