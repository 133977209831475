<template>
<div class="base-outer">
    <div class="img-swich-wrap">
        <div class="img-swich" @click="showIdx" @touchstart="touchstart" @touchmove="touchmove" @touchend="touchend" :style="'transform:translateX(-'+ (swiperIndex-0.5) * 50 +'vw);'+'transition:'+ trigger">
            <div class="img-wrp" :class="{'on':swiperIndex == index}" v-for="(item,index) in list" :key="index" :style="'transition:'+ shapTrigger">
                <div class="img-tit">{{item.name}}</div>
                <img :src="item.pic">
            </div>
        </div>
    </div>
</div>
</template>

<script>
let st = "";
export default {
  data() {
    return {
      originLen: 0,
      swiperIndex: 3,
      moveTrigger: true,
      trigger: "transform 300ms ease",
      shapTrigger: "transform 300ms ease",
      moveX: 0,
      moveY: 0,
      startX: 0,
      startY: 0,
      list: [
        {
          name: "第一中学",
          pic: require("../assets/schoolModel.jpg")
        },
        {
          name: "第二中学",
          pic: require("../assets/schoolModel0.jpg")
        },
        {
          name: "第三中学",
          pic: require("../assets/schoolModel1.jpg")
        },
        {
          name: "第四中学",
          pic: require("../assets/schoolModel2.jpg")
        },
        {
          name: "第五中学",
          pic: require("../assets/schoolModel3.jpg")
        }
      ]
    };
  },
  mounted() {
    this.originLen = this.list.length;

    this.list.unshift(this.list[this.list.length - 1]);
    this.list.unshift(this.list[this.list.length - 2]);
    this.list.unshift(this.list[this.list.length - 3]);

    // console.log("list:", this.list);
  },
  methods: {
    addOne() {
      if (this.swiperIndex < this.originLen + 1) {
        clearTimeout(st);
        this.swiperIndex += 1;
        this.trigger = "transform 300ms ease";
        this.shapTrigger = "transform 300ms ease";
      } 
      if (this.swiperIndex == this.originLen + 1) {
        st = setTimeout(() => {
          this.trigger = "transform 0 ease";
          this.shapTrigger = "transform 0 ease";
          this.swiperIndex = 1;
        }, 500);
      }
    //   console.log("swiperIndex add", this.swiperIndex);
    },
    munisOne() {
      if (this.swiperIndex > 1) {
        clearTimeout(st);
        this.swiperIndex -= 1;
        this.trigger = "transform 300ms ease";
        this.shapTrigger = "transform 300ms ease";
      } 
      if (this.swiperIndex == 1) {
        st = setTimeout(() => {
          this.trigger = "transform 0 ease";
          this.shapTrigger = "transform 0 ease";
          this.swiperIndex = this.list.length - 2;
        }, 500);
      }
    //   console.log("swiperIndex minus", this.swiperIndex);
    },
    touchstart(e) {
      // e.preventDefault();
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },

    touchmove(e) {
      // e.preventDefault();
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
    },
    touchend() {
      if (this.startX - this.moveX <= -70) {
        this.munisOne();
      } else {
        this.addOne();
      }
    }
  }
};
</script>

<style scoped>
.base-outer {
  overflow: hidden;
  background-color: #fff;
}

.img-swich-wrap {
  position: relative;
  overflow: hidden;
  height: 53vw;
  display: flex;
  align-items: center;
}

.img-swich {
  display: flex;
}

.img-wrp {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 42vw;
  height: 30vw;
  flex: none;
  background-color: none;
  margin: 0 4vw;
  border-radius: 6px;
  overflow: hidden;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.img-wrp.on {
  transform: scale(1.3);
  z-index: 999;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
}

.img-wrp img {
  position: absolute;
  z-index: 8;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: auto;
}

.img-tit {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.6);
}
</style>
