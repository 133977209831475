<template>
<div class="li-item">
    <router-link :to="'/NewsDetail/'+ids">
        <div class="li-content">
            <div class="li-title">{{title}}</div>
            <div class="li-reads">{{reads}}人已阅读</div>
        </div>
        <div class="li-img-wrp" :style="'background-image:url('+(imgsrc?domin+imgsrc:domin+expImg)+')'"></div>
    </router-link>
</div>
</template>

<script>
export default {
    props: {
        ids:"",
        reads: 0,
        title: "",
        imgsrc: ""
    },
    data() {
        return {
            expImg: require('../assets/expImg.jpg')
        }
    }
}
</script>

<style scoped>
.li-item a{
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    margin: 5px;
    border-bottom: 1px solid #e9e9e9;
    font-size: 13px;
    line-height: 2.5;
}

.li-content {
    width: calc(100% - 100px);
    box-sizing: border-box;
    padding: 0 5px;
}

.li-title {
    color: #686767;
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.li-reads {
    color: #c7c7c7;
}

.li-img-wrp {
    width: 100px;
    height: 70px;
    border-radius: 8px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
</style>
